import dayjs from '@/plugins/dayjs'
import compact from 'lodash/compact'
import map from 'lodash/map'
import { titleCase } from 'title-case'
import Vue from 'vue'

function utcNow() {
  return dayjs().utc().format()
}

function isDateValid(date) {
  return date?.length === 10 && dayjs(date, ['YYYY-MM-DD', 'MM/DD/YYYY'], 'es', true).isValid()
}

function ageInYears(date) {
  return isDateValid(date) ? dayjs().diff(dayjs(date), 'year') : false
}

function ageInMonths(date) {
    return isDateValid(date) ? dayjs().diff(dayjs(date), 'month') : false
}

function ageInDays(date) {
  return isDateValid(date) ? dayjs().diff(dayjs(date), 'days') : false
}

function getAgeDescription(dob) {
  if (!isDateValid(dob)) return ''

  const years = ageInYears(dob)
  const months = ageInMonths(dob)
  const days = ageInDays(dob)

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} old`
  } else if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} old`
  } else if (days >= 1) {
    return `${days} day${days > 1 ? 's' : ''} old`
  } else if (days === 0) {
    return `newborn`
  } else {
    return 'Unknown age'
  }
}

function isToday(date) {
  return dayjs().isSame(date, 'day')
}

function isObject(candidate) {
  return (typeof candidate === 'object' || typeof candidate === 'function') && (candidate !== null)
}

function isObjectEmpty(candidate) {
  return isObject(candidate) ? Object.keys(candidate).length === 0 : true
}

function isArray(candidate) {
  return Array.isArray(candidate)
}

function isEmpty(value) {
  return (value === null || value === undefined || value === '')
    || (isArray(value) && value.length === 0)
    || (isObject(value) && isObjectEmpty(value))
}

function isMixedCase(string) {
  return string.toUpperCase() !== string && string.toLowerCase() !== string
}

function notEmpty(value) {
  return (!isEmpty(value))
}

// Search an array for an item where the title contains case insensitive text
function itemTitleContains(array, contains) {
  return array.some(x => x.title && x.title.toLowerCase().indexOf(contains) !== -1)
}

// Check if string contains case sensitive text
function strContains(str, contains) {
  return !str ? false : str.toLowerCase().indexOf(contains) !== -1
}

function strToBoolNull(string) {
  if (string === null || string === '' || typeof string === 'undefined') return null

  return String(string).toLowerCase() === 'true'
}

function formatCurrency(amount = 0, decimals = 2) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
  })

  return formatter.format(amount)
}

function randomString(array) {
  if (!isArray(array)) return ''

  return ` ${array[Math.floor(Math.random() * array.length)]} `
}

function toLowerCase(string) {
  return typeof string === 'string' ? string.toLowerCase() : ''
}

function toUpperCase(string) {
  return typeof string === 'string' ? string.toUpperCase() : ''
}

function toUniqueArray(array, flatten = false) {
  if (!Array.isArray(array)) return []
  if (flatten && array.length && isObject(array[0])) {
    return [...new Set(compact(map(array, 'title')))]
  }

  return [...new Set(compact(array))]
}

function removeNoneOther(array) {
  return toUniqueArray(array, true).filter(x => x !== 'Other' && x !== 'None')
}

// First character of string uppercase (sentence case)
function toProperCase(string) {
  let cleanedString = ''
  if (string.charAt(1) !== '.') {
    cleanedString = String(string).trim().toLowerCase()
  }
  if (!cleanedString) return ''

  return cleanedString.charAt(0).toUpperCase() + cleanedString.substring(1)
}

// First character of every word uppercase
function toProperCaseAll(string) {
  let words = String(string).trim().toLowerCase().split(' ')
  if (words.length === 0) return ''

  const newString = words.map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
  words = String(newString).trim().split('/')
  if (words.length === 1) return newString

  return words.map(word => word.charAt(0).toUpperCase() + word.substring(1)).join('/')
}

// Convert name to proper case
function toNameCase(string) {
  let newString = String(string).trim()
  if (newString.length === 0) return ''

  const wordsSpace = newString.split(' ')
  const wordsDash = newString.split('-')
  if (wordsSpace.length && wordsDash.length < 2) {
    wordsSpace.forEach((word, index) => {
      if (!this.isMixedCase(word)) {
        let newWord = this.toProperCase(word)
        if (!newWord) {
          newWord = word
        } else if ((newWord.substring(0, 2) === 'Mc' || newWord.substring(0, 2) === "O'") && newWord.length > 3) {
          newWord = newWord.substring(0, 2) + word.charAt(2).toUpperCase() + word.substring(3).toLowerCase()
        }
        wordsSpace[index] = newWord
      } else wordsSpace[index] = word
    })
    newString = wordsSpace.join(' ')
  } else if (wordsDash.length > 1 && wordsSpace.length < 2) {
    wordsDash.forEach((word, index) => {
      if (!this.isMixedCase(word)) {
        let newWord = this.toProperCase(word)
        if ((newWord.substring(0, 2) === 'Mc' || newWord.substring(0, 2) === "O'") && newWord.length > 3) {
          newWord = newWord.substring(0, 2) + word.charAt(2).toUpperCase() + word.substring(3).toLowerCase()
        }
        wordsDash[index] = newWord
      } else wordsDash[index] = word
    })
    newString = wordsDash.join('-')
  }

  return newString
}

// Transform string into title case following AP/APA style title case
function toTitleCase(string) {
  return titleCase(String(string))
}

function toOrdinal(number) {
  const ordinals = ['th', 'st', 'nd', 'rd']
  const v = number % 100

  return number + (ordinals[(v - 20) % 10] || ordinals[v] || ordinals[0])
}

function articleAge(ageNumber, ageDesc) {
  // Check if ageNumber is an integer
  if (!Number.isInteger(ageNumber) || ageNumber < 0) {
    return 'an unknown age '
  }

  if (ageNumber === 0 && ageDesc === 'day') {
    return `a newborn `
  } else {
    const indefiniteArticle = [8, 11, 18, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89].includes(ageNumber) ? 'an' : 'a'
    return `${indefiniteArticle} ${ageNumber} ${ageDesc} old `
  }
}

function listFormat(array, final, stringCase) {
  let newList = ''
  if (final !== false && array.length === 2) {
    newList = ` ${array[0]} ${String(final === true ? 'and' : final)} ${array[1]} `
  } else if (final !== false && array.length > 2) {
    newList = ` ${array.slice(0, -1).join(', ')}${String(final === true ? ', and' : `, ${final}`)} ${array.slice(-1)} `
  } else {
    newList = ` ${array.join(', ')} `
  }
  switch (String(stringCase).toLowerCase()) {
    case 'proper':
      return toProperCase(newList)
    case 'title':
      return toTitleCase(newList)
    case 'all':
      return toProperCaseAll(newList)
    case 'lc':
      return newList.toLowerCase()
    case 'uc':
      return newList.toUpperCase()
    case 'none':
      return newList
    default:
      return newList
  }
}

function toList(array, other = null, final = 'and', stringCase = 'proper') {
  if (!Array.isArray(array)) return array
  const newArray = toUniqueArray(array, true).filter(x => x !== 'Other')

  if (!other && !array?.length) return ''

  if (other) newArray.push(other)

  return listFormat(newArray, final, stringCase)
}

function toListComma(array, other = null, stringCase = 'proper') {
  return toList(array, other, false, stringCase)
}

function toListCommaAnd(array, other = null, stringCase = 'proper') {
  return toList(array, other, 'and', stringCase)
}

function toListCommaOr(array, other = null, stringCase = 'proper') {
  return toList(array, other, 'or', stringCase)
}

function toListReturn(array, other = null) {
  if (!Array.isArray(array)) return array
  let newArray = toUniqueArray(array, true)
  if (!other && newArray === []) return ''
  if (other) {
    newArray = newArray.filter(x => x !== 'Other')
    newArray.push(other)
  }

  return ` ${newArray.join('<br>')} `
}

function clean(string, from = null, emptyWarning = true) {
  // Sentence and comma formatting
  let newString = string.replaceAll(' .', '.').replaceAll('.  ', '!s!').replace(/  +/g, ' ').replaceAll('!s!', '.  ')
  newString = newString.replaceAll(' ,', ',').replaceAll(' .', '.').replaceAll('..', '.').replaceAll(' :', ':')
  newString = newString.replaceAll('\n  ', '\n').replaceAll('\n ', '\n')
  newString = newString.replaceAll('<br>  ', '<br>').replaceAll('<br> ', '<br>')

  newString = newString.replaceAll("patient's a wound", "patient's wound")
  newString = newString.replaceAll('their a ', 'their ')
  newString = newString.replaceAll('and , and', 'and')

  // Replace < char to avoid truncation for false html recognition only when reviewing note
  if (from === 'Wound Body' || from === 'Wound Text' || from === 'Wound Footer' || from === 'Podiatry' || from === 'Primary Care' || from === 'Psychiatry' || from === 'Psychology') {
    newString = newString.replaceAll(/\s*<(?!(\w+|\/))/g, ' &lt; ')
  }

  // Change multiple line breaks into a max of two
  newString = newString.replace(/\n\s*\n\s*\n/g, '\n\n')
  newString = newString
    .replace(/^(<br\s*\/?>)+|(<br\s*\/?>)+$/gi, '') // Remove leading and trailing <br> tags
    .replace(/(<br\s*\/?>){3,}/gi, '<br><br>'); // Replace multiple consecutive <br> tags with two

  newString = newString.trim()

  if (newString.slice(-1) === ',') newString = newString.slice(0, -1)

  if (emptyWarning && isEmpty(newString)) {
    Vue.store.dispatch('notify', { value: 'Values entered don\'t generate output.', color: 'warning' })
  }

  if (from) console.log(`${from} AI Generated`)

  return newString
}

function textAreaHtml(string) {
  return typeof string === 'string' ? string.trim().replace(/\n\s*\n\s*\n/g, '\n\n').replaceAll('\n', '<br>') : ''
}

function textAreaFlat(string) {
  return typeof string === 'string' ? string.trim().replaceAll('\n', ' ').replaceAll('  ', ' ').trim() : ''
}

function processCommError(error, from = null, toast = false) {
  let formattedError = from ? `${toProperCase(from)} - ` : ''
  formattedError += `${error.message}`
  if (formattedError.includes('401')) formattedError += ' (invalid login credentials)'
  else if (formattedError.includes('404')) formattedError += ' (file not found)'
  else if (formattedError.includes('500')) formattedError += ' (internal server error)'
  console.log(formattedError)
  if (toast) Vue.store.dispatch('notify', { value: formattedError, color: 'error' })

  return formattedError
}

// Recursive function to deep freeze an object
function deepFreeze(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // Freeze properties before freezing the object
  Object.keys(obj).forEach(key => {
    obj[key] = deepFreeze(obj[key])
  })

  return Object.freeze(obj)
}

// Freeze synced attachments and treatments from signed encounters
function freezeAttachmentsAndTreatments(patient) {
  if (patient.all_attachments) {
    patient.all_attachments.forEach(attachment => {
      if (!attachment.updated) {
        // Set the 'data' property to 'placeholder'
        attachment.data = 'placeholder'

        // Freeze attachments that are already synced.
        deepFreeze(attachment)
      }
    })
  }

  if (patient.wounds) {
    patient.wounds.forEach(wound => {
      if (wound.treatments) {
        wound.treatments = wound.treatments.map(treatment =>

          // Freeze treatments where the related encounter is signed.
          (treatment.encounter && treatment.encounter.is_signed
            ? deepFreeze(treatment)
            : treatment))
      }
    })
  }
}

// Freeze signed encounters that don't belong to the current user.
function freezeSignedEncounters(encounter) {
  if (encounter.is_signed && encounter.is_synced && encounter.created_by_user_id !== Vue.auth.user().id) {
    deepFreeze(encounter)
  }
}

Object.defineProperties(Vue.prototype, {
  $custom: {
    get() {
      return {
        utcNow,
        isDateValid,
        ageInYears,
        ageInMonths,
        ageInDays,
        getAgeDescription,
        isToday,
        formatCurrency,
        isObject,
        isObjectEmpty,
        isArray,
        isMixedCase,
        isEmpty,
        notEmpty,
        itemTitleContains,
        strContains,
        strToBoolNull,
        randomString,
        removeNoneOther,
        toLowerCase,
        toUpperCase,
        toUniqueArray,
        toProperCase,
        toProperCaseAll,
        toNameCase,
        toTitleCase,
        toOrdinal,
        articleAge,
        toListComma,
        toList,
        toListCommaAnd,
        toListCommaOr,
        toListReturn,
        clean,
        textAreaHtml,
        textAreaFlat,
        processCommError,
        deepFreeze,
        freezeAttachmentsAndTreatments,
        freezeSignedEncounters,
      }
    },
  },
})
